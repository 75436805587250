/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { message, Modal } from "antd";
import { ReloadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { FetchPost } from "../Common";
import loadingImg from "./../assets/img/loading.png";
import checkedImg from "./../assets/img/checked.png";
import loadingBlack from "./../assets/img/loading_black.png";
import step from "./../assets/img/login.png";
import { productionName } from "../resource/config";

const ModalTitle = `${productionName}扫码登录`;

// WAIT_SCAN("等待扫描"),
//         WAIT_CONFIRM("等待确认"),
//         EXPIRED("已过期"),
//         SUCCESS("验证成功"),
//         WAIT_REDIRECT("等待跳转"),

//         /** 非缓存记录字段，这个字段仅仅用来返回给前端 */
//         HAS_SCANNED("已经被扫描"),
//         REDIRECT("跳转"),
//         CANCEL("取消"),
//         WAIT_MANAGER_VERIFY("等待管理员验证"),
//         INVALID_USER("非管员用户");

const STATUS = {
  //   WAIT_SCAN("等待扫描"),
  //   WAIT_CONFIRM("等待确认"),
  //   EXPIRED("已过期"),
  //   SUCCESS("验证成功"),
  //   WAIT_REDIRECT("等待跳转"),
  //   /** 非缓存记录字段，这个字段仅仅用来返回给前端 */
  //   HAS_SCANNED("已经被扫描"),
  //   REDIRECT("跳转");
  WAIT_SCAN: "WAIT_SCAN",
  WAIT_CONFIRM: "WAIT_CONFIRM", // 扫描完成后  等待手机端确认
  EXPIRED: "EXPIRED", // 二维码已过期
  SUCCESS: "SUCCESS", // 验证成功
  WAIT_REDIRECT: "WAIT_REDIRECT", // 确认登录，等待跳转
  HAS_SCANNED: "HAS_SCANNED", //
  REDIRECT: "REDIRECT",
  FETCHING: "FETCHING", // 加载中
  CANCEL: "CANCEL",
  WAIT_MANAGER_VERIFY: "WAIT_MANAGER_VERIFY", // 等待管理员确认
  NOACCESS_RIGHT: "NOACCESS_RIGHT", // 当前用户暂无权限访问
  NOAUTHN_RIGHT: "NOAUTHN_RIGHT", // 当前用户暂无权限访问
};
const maskStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  background: "rgba(0,0,0,0.7)",
};
const centerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};
// generalGetQrCode
// {
//  "returnUrl":""
// }
// {
//  "appName":""
//     "frontType":""
//     "qrCodeUrl":""
//     "pollingUrl":""
//     "waitScanExpire":""
//     "waitConfirmExpire":""
// }
let scanTimer = -1;
let pollingTimer = -1;
let waitConfirmTimer = -1;
let authTimer = -1; // 认证
function UserHead(name, avatar) {
  const imgA = (
    <div>
      <img style={{ width: 66, borderRadius: 33 }} src={avatar} />
      <img
        src={checkedImg}
        style={{
          width: 24,
          display: "block",
          position: "absolute",
          top: 104,
          left: 104,
        }}
      />
    </div>
  );
  const strA = (
    <div>
      <p style={{ margin: 0 }} className="avatar-first-chr">
        {name ? name.slice(0, 1) : ""}
      </p>
      <img
        src={checkedImg}
        style={{
          width: 24,
          display: "block",
          position: "absolute",
          top: 104,
          left: 104,
        }}
      />
    </div>
  );
  return avatar ? imgA : strA;
}
export default function ScanLoginFrame(props) {
  const [qrcodeStatus, setQrcodeStatus] = useState(STATUS.WAIT_SCAN);
  const [avatar, setAvatar] = useState("");
  const [realName, setRealName] = useState("");
  const [qrcode, setQrcode] = useState({});
  const [verifiedCount, setVerifiedCount] = useState(0);
  const [verifyCount, setVerifyCount] = useState(0);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [loginQrCodeId, setLoginQrCodeId] = useState("");
  const [visibles, setVisibles] = useState(false);

  function handleCancel() {
    setVisibles(false);
  }
  // 是否扫码
  function startScanTimeout(time) {
    clearTimeout(scanTimer);
    scanTimer = setTimeout(() => {
      clearTimeout(waitConfirmTimer);
      clearInterval(pollingTimer);
      setQrcodeStatus(STATUS.EXPIRED);
    }, time);
  }
  // 扫码后是否点击确认
  function startWaitConfirmTimeout(time) {
    clearTimeout(waitConfirmTimer);
    waitConfirmTimer = setTimeout(() => {
      setQrcodeStatus(STATUS.EXPIRED);
    }, time);
  }
  // 1秒请求一次
  function startPollingTimer(qrcode) {
    clearInterval(pollingTimer);
    pollingTimer = setInterval(() => {
      pollingQrcode(qrcode);
    }, 4000);
  }

  // 获取二维码
  let searchParams = new URL(window.location.href).searchParams;
  let paramClientId = searchParams.get("clientId");
  let paramReturnUrl = searchParams.get("ReturnUrl");
  let returnUrl =
    encodeURIComponent(paramReturnUrl) +
    `&clientId=${paramClientId}&x-client=WEB`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getQrcode(loginQrCodeId = "") {
    setQrCodeUrl("");
    setQrcodeStatus(STATUS.FETCHING); // 正在请求状态
    const url = `${window.PROPS.path.generalGetQrCode}?ReturnUrl=${returnUrl}`;
    const fetchUrl = loginQrCodeId
      ? `${url}&loginQrCodeId=${loginQrCodeId}`
      : url;
    FetchPost(fetchUrl, {}, (data) => {
      if (data.errorCode === "0") {
        let content = data.content || {};
        setQrcode(content);
        setQrcodeStatus(STATUS.WAIT_SCAN); // 等待扫码
        // 扫描超时计时
        startScanTimeout(1000 * content.waitScanExpire);
        // 开始轮训登录状态
        startPollingTimer(content);
      } else if (data.errorCode === "101600014") {
        message.error(data.errorMsg);
      } else {
        setQrcodeStatus(STATUS.EXPIRED);
      }
    });
  }
  // 刷新二维码状态---是否扫码
  function pollingQrcode(qrcode) {
    // console.log('当前轮询地址===>', qrcode.pollingUrl)
    FetchPost(`${qrcode.pollingUrl}?clientId=${paramClientId}`, {}, (data) => {
      if (data && data.errorCode === "0") {
        let content = data.content || {};
        setRealName(content.realName);
        setAvatar(
          content.avatar &&
            content.avatar.split("?").join("/image/webp/scale/1?") +
              "&quality=0.1",
        );
        setQrcodeStatus(content.qrStatus);
        clearTimeout(authTimer);
        setRealName(content.realName);
        setAvatar(
          content.avatar &&
            content.avatar.split("?").join("/image/webp/scale/1?") +
              "&quality=0.1",
        );
        // 完成扫码后 需在手机端点击确认登陆，等待超时计时
        // console.log('接口返回的轮询地址===>', qrcode.pollingUrl, '当前认证人数', content.verifiedCount)
        if (content.qrStatus === STATUS.WAIT_CONFIRM) {
          startWaitConfirmTimeout(1000 * qrcode.waitConfirmExpire);
          // 超时后 或者 确认登陆后 需停掉timer
        } else if (
          content.qrStatus === STATUS.REDIRECT ||
          content.qrStatus === STATUS.EXPIRED
        ) {
          clearInterval(pollingTimer);
          clearTimeout(waitConfirmTimer);
          if (content.qrStatus === STATUS.REDIRECT) {
            // console.log('content==>',content)
            clearInterval(pollingTimer);
            clearTimeout(waitConfirmTimer);
            setTimeout(() => {
              props.verifySuccessCallback({
                content: {
                  ...content,
                  teamUserInfoList: [
                    {
                      teamId: content.teamId,
                      appId: content.appId,
                    },
                  ],
                },
              });
            }, 1000);
          }
          // 手机端点击取消登陆
          // 超时后 或者 确认登陆后 需停掉timer
        } else if (content.qrStatus === STATUS.CANCEL) {
          clearInterval(pollingTimer);
          clearTimeout(waitConfirmTimer);
          getQrcode();
        }
        if (content.loginQrCodeId && content.loginQrCodeId !== loginQrCodeId) {
          setLoginQrCodeId(content.loginQrCodeId || ""); // 获取二维码id
        }
        // 获取认证人的二维码
        if (
          content.authQrCode &&
          content.authQrCode.qrCodeUrl &&
          content.authQrCode.qrCodeUrl !== qrCodeUrl
        ) {
          setQrCodeUrl(content.authQrCode.qrCodeUrl); //认证二维码地址
          // clearTimeout(authTimer);
          // console.log(content.authQrCode.pollingUrl, 'content.authQrCodecontent.authQrCode')
          authTimer = setTimeout(() => {
            pollingQrcode(content.authQrCode);
          }, 4000);
        }
        // console.log(content.verifiedCount, 'content.verifiedCountcontent.verifiedCount')
        setVerifiedCount(content.verifiedCount || 0); // 已认证管理员认证数量
        setVerifyCount(content.verifyCount || 0); // 需要管理员认证数量
      }
    });
  }
  const initRef = useRef("");
  useEffect(() => {
    // console.log(initRef)
    if (!initRef.current) {
      getQrcode();
      initRef.current = "web";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: 430,
        height: 490,
        position: "relative",
      }}
    >
      <Modal
        width={646}
        title={ModalTitle}
        visible={visibles}
        onCancel={handleCancel}
        footer={null}
      >
        <img src={step} width="598" height="315" />
      </Modal>

      <div
        style={{
          // border: "1px solid #F0F3F3",
          textAlign: "center",
          boxSizing: "border-box",
          padding: "60px 0 60px 0",
          // boxShadow: " 0 4px 12px 0 rgba(0,0,0,0.1)",
          // borderRadius: 6,
          // minHeight: '406px'
        }}
      >
        <h1
          style={{
            lineHeight: 1,
            color: "#232930",
            fontSize: 21,
            marginBottom: 60,
            textAlign: "center",
          }}
        >
          欢迎使用{productionName}管理后台
        </h1>
        {qrcodeStatus === STATUS.WAIT_SCAN ||
        qrcodeStatus === STATUS.FETCHING ||
        qrcodeStatus === STATUS.SUCCESS ||
        qrcodeStatus === STATUS.WAIT_MANAGER_VERIFY ||
        qrcodeStatus === STATUS.EXPIRED ? (
          <>
            <h1
              style={{
                lineHeight: 1,
                color: "#232930",
                fontSize: 16,
                marginBottom: 16,
              }}
            >
              {verifyCount > 0 ? "扫码确认" : "扫码登录"}
            </h1>
            <div
              style={{
                lineHeight: 1,
                color: "#6E6F71",
                fontSize: 14,
                marginBottom: 16,
              }}
            >
              {verifyCount > 0 ? (
                <p>
                  '请其他管理员使用手机APP“${`${productionName}`}
                  ”扫描二维码进行确认'
                </p>
              ) : (
                <p>使用{productionName}APP扫码登录</p>
              )}
            </div>
          </>
        ) : null}
        <div
          style={{
            position: "relative",
            width: 184,
            height: 184,
            margin: "0 auto",
          }}
        >
          <img width="184" height="184" src={qrCodeUrl || qrcode.qrCodeUrl} />
          {/* 过期 */}
          {qrcodeStatus === STATUS.EXPIRED ? (
            <div
              style={{ ...maskStyle, ...centerStyle }}
              onClick={getQrcode(loginQrCodeId)}
            >
              {/* <img style={{width: 20}} className="xuanzhun" src={`${window.PROPS.imgPath}/loading.png`} alt = 'ad_bottom' /> */}
              <ReloadOutlined style={{ color: "#fff", fontSize: 20 }} />
              <p style={{ fontSize: 14, color: "#fff", margin: "10px 0 0 0" }}>
                刷新二维码
              </p>
            </div>
          ) : null}
          {/* 待确认 */}
          {qrcodeStatus === STATUS.WAIT_CONFIRM ||
          qrcodeStatus === STATUS.REDIRECT ? (
            <div
              style={{
                ...maskStyle,
                ...centerStyle,
                background: "rgba(0,0,0,0.6)",
              }}
            >
              {UserHead(realName, avatar)}
            </div>
          ) : null}
          {qrcodeStatus === STATUS.FETCHING ? (
            <div style={{ ...maskStyle, ...centerStyle }}>
              <img
                style={{ width: 20 }}
                className="xuanzhuan"
                src={loadingImg}
              />

              <p style={{ fontSize: 14, color: "#fff", margin: "10px 0 0 0" }}>
                加载中...
              </p>
            </div>
          ) : null}
        </div>
        {/* 待确认需要多少认证人 */}
        {verifyCount !== 0 &&
          (qrcodeStatus === STATUS.SUCCESS ||
            qrcodeStatus === STATUS.WAIT_MANAGER_VERIFY ||
            qrcodeStatus === STATUS.WAIT_SCAN) && (
            <div
              style={{ fontSize: "14px", color: "#6E6F71", paddingTop: "8px" }}
            >
              已确认<span style={{ color: "#306FDC" }}>{verifiedCount}</span>/
              <span style={{ color: "#306FDC" }}>{verifyCount}</span>人
            </div>
          )}
        {/* 错误提示 */}
        {qrcodeStatus === STATUS.INVALID_USER && (
          <div
            style={{
              fontSize: "16px",
              color: "#F5222D",
              paddingTop: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExclamationCircleFilled />
            <span
              style={{ fontSize: "14px", color: "#F5222D", paddingLeft: "8px" }}
            >
              当前用户非管理员
            </span>
          </div>
        )}
        {qrcodeStatus === STATUS.NOAUTHN_RIGHT && (
          <div
            style={{
              fontSize: "16px",
              color: "#F5222D",
              paddingTop: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExclamationCircleFilled />
            <span
              style={{ fontSize: "14px", color: "#F5222D", paddingLeft: "8px" }}
            >
              管理员暂无认证权限
            </span>
          </div>
        )}
        {qrcodeStatus === STATUS.NOACCESS_RIGHT && (
          <div
            style={{
              fontSize: "16px",
              color: "#F5222D",
              paddingTop: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ExclamationCircleFilled />
            <span
              style={{ fontSize: "14px", color: "#F5222D", paddingLeft: "8px" }}
            >
              当前用户暂无权限访问
            </span>
          </div>
        )}
        {/* 正在加载 */}
        {qrcodeStatus === STATUS.WAIT_CONFIRM ||
        qrcodeStatus === STATUS.REDIRECT ||
        qrcodeStatus === STATUS.WAIT_REDIRECT ? (
          <>
            <h1
              style={{
                lineHeight: 1,
                color: "#232930",
                fontSize: 24,
                marginTop: 8,
                marginBottom: 12,
              }}
            >
              扫码成功
            </h1>
            <p
              style={{
                lineHeight: 1,
                color: "#6E6F71",
                fontSize: 12,
                marginBottom: 0,
              }}
            >
              {qrcodeStatus === STATUS.REDIRECT ||
              qrcodeStatus === STATUS.WAIT_REDIRECT ? (
                <>
                  正在登录{" "}
                  <img width="12" className="xuanzhuan" src={loadingBlack} />
                </>
              ) : (
                "请在移动端确认登录"
              )}
            </p>
          </>
        ) : null}
      </div>
      {process.env.REACT_APP_KEFU_TEL && (
        <p
          style={{
            fontSize: "12px",
            color: "#aaaeb5",
            textAlign: "center",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            margin: 0,
          }}
        >
          客服电话：{process.env.REACT_APP_KEFU_TEL}
        </p>
      )}
    </div>
  );
}
