import React, { PureComponent } from "react";
import VCodeInput from "../components/VCodeInput";
import LoginButton from "../components/LoginButton";
import { request } from "../Common";
import { message } from "antd";

export default class VCodeInputFrame extends PureComponent {
  state = {
    verCode: null,
    buttonStatus: "disabled",

    outSeconds: 60,
    resendTigDisplay: "none",
    outSecondTextDisplay: "none",
  };

  componentDidMount() {
    this.showOutSecondsTag(this.props.mobile, this.props.countryCode);
  }

  onVerCodeChange = (verCode) => {
    this.setState(
      {
        verCode: verCode,
      },
      () => {
        if (verCode.length === 6) {
          this.setState({ buttonStatus: "nomal" });
          this.onButtonClick();
        } else {
          this.setState({ buttonStatus: "disabled" });
        }
      },
    );
  };

  onButtonClick() {
    this.setState({ buttonStatus: "loading" });
    this.verifyVerCode(
      this.props.mobile,
      this.props.countryCode,
      this.state.verCode,
    );
  }

  jointAuthMsg(basePath) {
    let escapeReturnUrl = escape(this.props.returnUrl);
    return (
      basePath +
      "?ReturnUrl=" +
      escapeReturnUrl +
      "&clientId=" +
      this.props.clientId
    );
  }

  showOutSecondsTag(mobile, countryCode) {
    let key = "me-" + mobile + "-" + countryCode;
    let cacheTime = localStorage.getItem(key);
    if (cacheTime) {
      let seconds = parseInt(
        (60000 - (new Date().getTime() - cacheTime)) / 1000,
      );
      if (seconds > 0) {
        this.setState({ outSeconds: seconds }, () => {
          this.beginOutSeconds(() => localStorage.removeItem(key));
        });
        return;
      }
    }
    localStorage.setItem(key, new Date().getTime());
    this.beginOutSeconds(() => localStorage.removeItem(key));
  }

  beginOutSeconds = (outCallBack) => {
    this.setState(
      {
        outSecondTextDisplay: "block",
        resendDisplay: "none",
      },
      () => {
        this.interval = setInterval(() => {
          let nowSecond = this.state.outSeconds - 1;
          this.setState({ outSeconds: nowSecond }, () => {
            if (this.state.outSeconds === 0) {
              clearInterval(this.interval);
              this.setState(
                {
                  outSeconds: 60,
                  outSecondTextDisplay: "none",
                  resendDisplay: "block",
                },
                () => outCallBack(),
              );
            }
          });
        }, 1000);
      },
    );
  };

  async verifyVerCode(mobile, countryCode, verCode) {
    if (!verCode || verCode.length !== 6) {
      message.error("请填写验证码!");
      return;
    }
    let data = await request(
      `/passport/api/login/by/verCode?ReturnUrl=${encodeURIComponent(
        this.props.returnUrl,
      )}&clientId=${encodeURIComponent(this.props.clientId)}`,
      {
        accountType: "GENERAL",
        countryCode,
        keyword: mobile,
        verCode,
      },
    );
    if (
      data &&
      Object.prototype.toString.call(data.content) !== "[object Null]"
    ) {
      this.props.verifySuccessCallback(data);
    } else {
      data.errorMsg && message.error(data.errorMsg || "验证码无效！");
    }

    // FetchPost(
    //     this.jointAuthMsg(window.PROPS.path.verifyVerCode), {
    //         keyword: mobile,
    //         countryCode: countryCode,
    //         verCode: verCode,
    //         accountType: 'GENERAL',
    //     }, data => {
    //         if (data.errorCode === '0') {
    //             this.props.verifySuccessCallback(data)
    //         } else {
    //             this.setState({buttonStatus: 'nomal'})
    //         }
    //     }
    // )
  }

  resendVCode = (mobile, countryCode) => {
    this.props.resendVCode(mobile, countryCode, () =>
      this.showOutSecondsTag(mobile, countryCode),
    );
  };

  render() {
    return (
      <div style={{ width: 311, userSelect: "none" }}>
        <div
          className="rollbackTag"
          style={{ marginBottom: 24, width: 60 }}
          onClick={this.props.clickRollback}
        >
          <img
            style={{ height: 15, width: "auto" }}
            src={`${window.PROPS.imagePath}/icon-back.png`}
            alt="back"
          ></img>
          &nbsp;&nbsp;返回
        </div>
        <div className="majorTitle" style={{ marginBottom: 13 }}>
          输入验证码
        </div>
        <div className="subTitle" style={{ marginBottom: 52 }}>
          验证码已发送至
          <font style={{ color: window.PROPS.styleCss }}>
            +{this.props.countryCode}&nbsp;{this.props.mobile}
          </font>
        </div>
        <div style={{ marginBottom: 44 }}>
          <VCodeInput
            onVerCodeChange={this.onVerCodeChange}
            focus={this.state.verCodeBoxGroupDisplay === "block"}
          ></VCodeInput>
        </div>
        <div style={{ marginBottom: 44 }}>
          <LoginButton
            onClick={this.onButtonClick}
            status={this.state.buttonStatus}
          ></LoginButton>
        </div>
        <div className="grayTag">
          <div style={{ display: this.state.outSecondTextDisplay }}>
            {this.state.outSeconds}秒后可重新获取验证码
          </div>
          <div
            className="linkText"
            style={{ display: this.state.resendDisplay }}
            onClick={() =>
              this.resendVCode(this.props.mobile, this.props.countryCode)
            }
          >
            重新获取验证码
          </div>
        </div>
      </div>
    );
  }
}
