import React, { PureComponent } from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default class LoginButton extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            bgColor : window.PROPS.styleCss,
        }
    }

    onMouseOver = () => {
        if (this.props.status === 'nomal') {
            this.setState({
                bgColor : window.PROPS.styleCss
            })
        }
    }

    onMouseLeave = () => {
        if (this.props.status === 'nomal') {
            this.setState({
                bgColor : window.PROPS.styleCss
            })
        }
    }

    onClick = () => {
        if (this.props.status === 'nomal') {
            this.props.onClick()
        }
    }

    render() {
        let buttonStyle = {
            fontFamily: 'PingFangSC-Regular',
            width: 311,
            height: 42,
            background: this.state.bgColor,
            borderRadius: 4,
            cursor: (this.props.status === 'disabled' || this.props.status === 'loading') ? 'not-allowed' : 'pointer',
            userSelect: 'none',
            textAlign: 'center',
            lineHeight: '42px',
            opacity : this.props.status === 'disabled' ? 0.3 : 1
        }
        
        return (
            <div>
                <div style = {buttonStyle} onClick = {this.onClick}
                    onMouseOver = {this.onMouseOver} 
                    onMouseLeave = {this.onMouseLeave}>
                    <div style = {{display : this.props.status === 'loading' ? 'block' : 'none'}}>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 16, color : 'white'}} spin />} />
                    </div>
                    <div style = {{fontSize: 14, color: 'rgba(255,255,255,1)'}}>
                        下一步
                    </div>
                </div>
            </div>
        )
    }
}
