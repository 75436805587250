import React from "react";
import {
  leftTitle,
  leftDesc,
  textColor,
  //   bgImgBottom,
  //   bgImgTop,
  bgColor,
} from "../resource/config";

export default function ADFrame() {
  return (
    <div
      className="leftbody"
      style={
        !bgColor.includes(".png")
          ? {
              width: 430,
              height: "100%",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              background: bgColor,
            }
          : {
              width: 430,
              height: "100%",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              backgroundImage: `url(${require("../assets/templateImgs/" +
                bgColor)})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }
      }
    >
      <JoyCarousel></JoyCarousel>
      <img
        src={require("../assets/templateImgs/carousel_fly.png")}
        alt=""
        style={{
          width: "230px",
          height: "auto",
          position: "absolute",
          top: "168px",
          left: "91px",
          zIndex: 1,
        }}
      />
      <img
        style={{ width: 430, height: "auto", position: "absolute", bottom: 0 }}
        src={require("../assets/templateImgs/bg_bottom.png")}
        alt="ad_bottom"
      ></img>
      <img
        style={{ width: 430, height: "auto", position: "absolute", top: 0 }}
        src={require("../assets/templateImgs/bg_top.png")}
        alt="ad_bottom"
      ></img>
    </div>
  );
}

function JoyCarousel() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "-40%",
        color: textColor,
      }}
    >
      <div className="largeTitle" style={{ marginBottom: "22px" }}>
        {leftTitle}
      </div>

      <div className="subTitle" style={{ width: "285px", textAlign: "center" }}>
        {leftDesc}
      </div>
    </div>
  );
}
