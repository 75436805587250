import React, { PureComponent } from 'react';
export default class MobileInput extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            border : '1px solid #dee0e3',
            canChange : true,
            value : ''
        }
    }

    onMouseOverBox = () => {
        if (this.state.canChange) {
            this.setState({
                border : `1px solid ${window.PROPS.styleCss}`
            })
        }
    }

    onMouseLeaveBox = () => {
        if (this.state.canChange) {
            this.setState({
                border : '1px solid #dee0e3'
            })
        }
    }

    onMobileChange = mobile => {
        let value = mobile.target.value;
        if (this.checkNumber(value) && value.length <= (this.props.valueLength ? this.props.valueLength : 11)) {
            this.setState({
                value : mobile.target.value
            })
            if (this.props.onMobileChange) {
                this.props.onMobileChange(mobile.target.value)
            }
        }
    }

    checkNumber(number) {
        var reg = /^[0-9]*$/
        return reg.test(number)
    }

    onFocus = () => {
        this.setState({
            border : `1px solid ${window.PROPS.styleCss}`,
            canChange : false
        })
    }

    onBlur = () => {
        this.setState({
            border : '1px solid #dee0e3',
            canChange : true
        })
    }

    onKeyUp = e => {
        if(e.keyCode === 13) {
            this.props.carriagReturn()
        }
    }

    render() {
        let inputBox = {
            fontFamily: 'PingFangSC-Bold',
            width: 309,
            height: 50,
            background: 'rgba(255,255,255,1)',
            border: this.state.border,
            borderRadius: 4,
            padding: 1,
            paddingLeft: 15,
            paddingRight: 15,
        }

        let inputStyle = {
            width : 311 - 32,
            height : 46,
            border : 'none',
            outline : 'none',
            fort : '400 11px system-ui',
            fontSize : 14,
            whiteSpace: 'nowrap',
            MozAppearance: 'textfield'
        }

        return (
            <div>
                <div style = {inputBox} onMouseOver = {this.onMouseOverBox} onMouseLeave = {this.onMouseLeaveBox}>
                    <input style = {inputStyle} 
                            placeholder = {this.props.placeholder} 
                            onChange = {this.onMobileChange}
                            onFocus = {this.onFocus}
                            onBlur = {this.onBlur}
                            onKeyUp = {this.onKeyUp}
                            value = {this.state.value}
                    ></input>
                </div>
            </div>
        )
    }
}
