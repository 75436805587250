import React, { PureComponent } from 'react'
import { HashRouter, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import LoginFrame from './pages/LoginFrame'

export default class App extends PureComponent {
    render() {
        return (
            <div>
                <HashRouter>
                    <Route exact path = '/' component = {LoginPage}></Route>
                    <Route path = '/frame' component = {LoginFrame}></Route>
                </HashRouter>
            </div>
        )
    }
}
