import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { cookieDomain } from "./resource/config";
import STYLE from './assets/css/login_v1.less';
import App from "./App";

window.PROPS = {
  defaultReturnUrl: process.env.REACT_APP_DUCC_DOMAIN_URL + "/admin",
  defaultClientId: "824cd7bf40af4a2892e11d781533208f",
  serverPath: "/passport/api",
  imagePath:
    "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/me-login/prod/img",
  // cookieDomain: ".tjjn-datalake.com",
  cookieDomain: cookieDomain || ".jdcloud.com",
  path: {
    verifyMobileLegal: "/verify/mobile/legal",
    sendVercode: "/login/send/verCode",
    verifyVerCode: "/login/by/verCode",
    exchangeOpenCode: "/login/exchange/openCode",
    generalGetQrCode: "/general/get/qrcode",
    generalListenQrStatus: "/general/polling/qrcode",
  },

  privacyProtocolChineseUrl: `https://me.jdcloud.com/protocol/gwork/privacy/chinese/`,
  privacyProtocolEnglishUrl: `https://me.jdcloud.com/protocol/gwork/privacy/chinese/`,
  serviceProtocolChineseUrl: `https://me.jdcloud.com/protocol/gwork/service/chinese/`,
  serviceProtocolEnglishUrl: `https://me.jdcloud.com/protocol/gwork/service/chinese/`,
  styleCss: STYLE.primaryColor
};

ReactDOM.render(<App></App>, document.getElementById("root"));
