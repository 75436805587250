import React, { Component } from 'react';


export default class SingleNumInput extends Component {

    constructor(props) {
        super(props)
        this.state = {
            border : '1px solid #dee0e3',
        }
    }

    componentDidMount() {
        if (this.props.code === this.props.nowFocusCode) {
            this.input.focus()
        }
    }

    componentDidUpdate() {
        if (this.props.code === this.props.nowFocusCode) {
            this.input.focus()
        }
    }

    onNumberInput =number=> {
        if(/^[0-9]*$/.test(number.target.value)){
            let value = number.target.value;
        if ( value.length === 1) {
            if (this.props.onNumberInput) {
                this.props.onNumberInput(number.target.value, this.props.code)
            }
        }
      } 
    }

    onNumberDel = () => {
        if (this.props.toPreInput) {
            this.props.toPreInput(this.props.code)
        }
    }
    
    onKeyUp = e => {
        if (e.keyCode === 8) {
            this.onNumberDel()
        } 
    }

    // checkNumber = number => {
    //     var reg = /^[0-9]*$/
    //     return reg.test(number)
    // }

    onFocus = () => {
        this.setState({
            border : `1px solid ${window.PROPS.styleCss}`,
        })
    }

    onBlur = number => {
        if (!number.target.value) {
            this.setState({
                border : '1px solid #dee0e3',
            })
        }
    }

    render() {
        let inputBoxStyle = {
            fontFamily: 'PingFangSC-Bold',
            width : 43,
            height : 50,
            background: 'rgba(255,255,255,1)',
            border: this.state.border,
            borderRadius: 4,
        }

        let inputStyle = {
            color: 'transparent',
            textShadow: '0 0 0 #000',
            border : 'none',
            outline : 'none',
            textAlign: 'center',
            fort : '400 11px system-ui',
            fontSize : 20,
            width : 41,
            height: 33,
            marginTop : 7,
            marginBottom : 8
        }

        return (
            <div style = {inputBoxStyle}>
                <input style = {inputStyle} ref = {input => this.input = input}
                        onChange = {this.onNumberInput}
                        onKeyUp = {this.onKeyUp}
                        value = {this.props.value}
                        onFocus = {this.onFocus}
                        onBlur = {this.onBlur}
                ></input>
            </div>
        )
    }
}
