// export const ResocrceConfig = {
//   szy: {
//     productionName: "数正云",
//     leftDesc: {
//       title: "全国机关事务云平台",
//       desc: "数正云是面向全国各级机关事务管理与服务，工作的数智化平台，为政府运行保障提供数智化支撑，让协作更加顺畅和触手可得",
//     },
//     textColor: "#ffffff",
//     bgColor: "linear-gradient(rgb(10, 119, 184) 0%, rgb(1, 82, 147) 100%)",
//     bgImg: {
//       bottom_bg:
//         "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/jgszy/bg_bottom.png",
//       top_bg:
//         "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/statics/jgszy/bg_top.png",
//     },
//   },
//   shb: {
//     productionName: "宿慧办",
//     leftDesc: {
//       title: "一站式协作",
//       desc: "宿慧办为您带来了前所未有的协作体验让协作变得更加顺畅和触手可得",
//     },
//     textColor: "rgba(35, 41, 48, 1)",
//     bgColor: "rgba(49,196,204,0.13)",
//     bgImg: {
//       bottom_bg:
//         "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/me-login/prod/img/ad_bottom.png",
//       top_bg:
//         "https://focus-desktop.s3.cn-north-1.jdcloud-oss.com/me-login/prod/img/ad_top.png",
//     },
//   },
// };

export const config = process.env.REACT_APP_NAME_ENG || "szy";
export const productionName = process.env.REACT_APP_NAME || "";
export const leftTitle = process.env.REACT_APP_LOGIN_FRONT_LEFTDESC_TITLE;
export const leftDesc = process.env.REACT_APP_LOGIN_FRONT_LEFTDESC_DESC;
export const textColor = process.env.REACT_APP_LOGIN_FRONT_TEXTCOLOR;
export const bgColor = process.env.REACT_APP_LOGIN_FRONT_BGCOLOR;
export const bgImgBottom = process.env.REACT_APP_LOGIN_FRONT_BGIMG_BOTTOM_BG;
export const bgImgTop = process.env.REACT_APP_LOGIN_FRONT_BGIMG_TOP_BG;
export const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;
