export function FetchPost(path, body, callback) {
  let path1 = path.startsWith("http") ? path : window.PROPS.serverPath + path;
  console.log(path1, 7);
  fetch(path1, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
    },
  })
    .catch((e) => {
      console.log(e);
    })
    .then((response) => response && response.json())
    .then((data) => {
      // console.log(data, 25)
      if (data && data.errorCode !== "0" && data.errorCode !== "10160021") {
        console.log(JSON.stringify(data));
        // openNotification("topRight", data.errorMsg);
      }
      callback(data);
    });
}

export function request(path, body, method = "post") {
  let datas = "";
  let API = "";
  if (path.indexOf("/janus") > -1) {
    if (path.indexOf("http") > -1) {
      datas = new URL(path).pathname?.split("/");
    } else {
      datas = path.split("/");
    }

    if (process.env.REACT_APP_NAME_ENG === "szy") {
      API = `${datas[1]}.${datas[2]}`;
    } else {
      API = `${datas[2]}.${datas[3]}`;
    }
  }
  if(process.env.NODE_ENV !== "production") {
    path = process.env.REACT_APP_DUCC_DOMAIN_URL + path;
  }

  return fetch(path, {
    method,
    body: JSON.stringify(body),
    headers: {
      "x-client": "iPad",
      "x-did": "iPad",
      "x-app": "hr3feu3hHPiQXL1fitO2",
      "x-api": API,
      "x-stage": "PROD",
      "x-nonce": Date.now().toString(36),
      "x-ts": Date.now(),
      "x-gw-version": 2,
      "x-app-version": "1.4.0",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errorCode === "0") {
        return res.content;
      } else {
        return res;
      }
    });
}
