import React, { PureComponent } from "react";
import MobileInput from "../components/MobileInput";
import LoginButton from "../components/LoginButton";
import { request } from "../Common";
import { message } from "antd";
import { productionName } from "../resource/config";

const ModalTitle = `欢迎使用${productionName}`;
export default class MobileInputFrame extends PureComponent {
  state = {
    mobile: null,
    countryCode: "86",
    buttonStatus: "disabled",
  };

  onMobileChange = (mobile) => {
    this.setState({ mobile: mobile });
    if (mobile.length === 11) {
      this.setState({ buttonStatus: "nomal" });
    } else {
      this.setState({ buttonStatus: "disabled" });
    }
  };

  onButtonClick = () => {
    this.setState({ buttonStatus: "loading" });
    this.verifyMobileLegal(this.state.mobile, this.state.countryCode);
  };

  jointAuthMsg(basePath) {
    let escapeReturnUrl = escape(this.props.returnUrl);
    return (
      basePath +
      "?ReturnUrl=" +
      escapeReturnUrl +
      "&clientId=" +
      this.props.clientId
    );
  }

  async verifyMobileLegal(mobile, countryCode) {
    if (!mobile) {
      message.error("请填写手机号!");
      return;
    }
    if (!countryCode) {
      message.error("请填写手机号国家编码!");
      return;
    }
    const res = await request(
      process.env.REACT_APP_API_URL + "/login/verifyMobileLegal",
      {
        countryCode,
        mobile,
      },
    );

    if (res?.result === "LEGAL") {
      this.props.sendVcode(
        mobile,
        countryCode,
        () => this.props.sendVcodeSuccess(mobile, countryCode),
        () => this.setState({ buttonStatus: "nomal" }),
      );
    } else {
      message.error("手机号不合法!");
      this.setState({ buttonStatus: "disabled" });
      return;
    }
    // FetchPost(
    //     window.PROPS.path.verifyMobileLegal, {
    //         mobile: mobile,
    //         countryCode: countryCode
    //     }, data => {
    //         if (data.errorCode === '0') {
    //             let result = data.content.result;
    //             if (result === 'ILLEGAL') {
    //                 message.error('手机号不合法!')
    //                 return
    //             }
    //             this.props.sendVcode(
    //                 mobile,
    //                 countryCode,
    //                 () => this.props.sendVcodeSuccess(mobile, countryCode),
    //                 () => this.setState({buttonStatus: 'nomal'})
    //             );
    //         } else {
    //             this.setState({buttonStatus: 'nomal'})
    //         }
    //     }
    // );
  }

  render() {
    return (
      <div style={{ width: 311, userSelect: "none" }}>
        <div className="rollbackTag" style={{ marginBottom: 24 }}></div>
        <div className="majorTitle">
          {ModalTitle}
        </div>
        <div style={{ marginBottom: 44 }}>
          <MobileInput
            onMobileChange={this.onMobileChange}
            carriagReturn={this.onButtonClick}
            placeholder="请输入手机号"
          ></MobileInput>
        </div>
        <div style={{ marginBottom: 44 }}>
          <LoginButton
            onClick={this.onButtonClick}
            status={this.state.buttonStatus}
          ></LoginButton>
        </div>
        {/* <div className="grayTag"></div> */}
      </div>
    );
  }
}
