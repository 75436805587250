import React, { Component } from 'react'
import LoginFrame from './LoginFrame'
import ADFrame from './ADFrame'
import cookie from 'react-cookies'

export default class LoginPage extends Component {

    state = {
        chinese: true,
        leftRegionDisplay: 'block',
        loginRegionWidth: 1,
    }

    componentWillMount() {
        let searchParams = new URL(window.location.href).searchParams
        let lang = searchParams.get('lang')
        lang = lang ? lang : cookie.load('focus-lang')
        this.setState({
            chinese: lang ? lang !== 'en_US' : true,
        }, () => {
            lang = this.state.chinese ? 'zh_CN' : 'en_US'
            cookie.save('focus-lang', lang, { domain: window.PROPS.cookieDomain, path: '/' })
        })
    }

    componentDidMount() {
        this.setLeftRegionDisplay()
        window.addEventListener('resize', this.setLeftRegionDisplay);
    }

    setLeftRegionDisplay = () => {
        if (document.body.clientWidth <= 1000) {
            this.setState({
                leftRegionDisplay: 'none',
                loginRegionWidth: document.querySelector('body').offsetWidth
            })
        } else {
            this.setState({
                leftRegionDisplay: 'block',
                loginRegionWidth: document.querySelector('body').offsetWidth - 430
            })
        }
    }

    render() {
        return (
            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <div style={{ position: 'absolute', width: 430, height: '100%', display: this.state.leftRegionDisplay }}>
                    <ADFrame></ADFrame>
                </div>
                <div style={{ position: 'absolute', height: '100%', right: 0, width: this.state.loginRegionWidth,overflow: 'auto',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                    }}>
                    <div style={{minHeight: '600px',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <LoginFrame
                            chinese={this.state.chinese}
                        ></LoginFrame>
                    </div>
                    {/* <div style={{position: 'absolute', bottom: 20, left:'50%', width: 285, marginLeft: -285/2}}>
                        <Protocol chinese={this.state.chinese}></Protocol>
                    </div> */}
                    {/* <img src={`${window.PROPS.imagePath}/me_top.png`} alt='me_top'
                        style={{position: 'absolute', right: 0, width: 153, height: 'auto'}}></img> */}
                </div>
            </div>
        )
    }
}


function Protocol({ chinese }) {
    return (
        <div className='protocolText'>
            {/* 登录即代表你已同意
            &nbsp;
            <a target="_blank" style={{ color: '#A0C3FF', textDecoration: 'none' }}
                href={chinese ? window.PROPS.serviceProtocolChineseUrl : window.PROPS.serviceProtocolEnglishUrl}
            >服务协议</a>
            &nbsp;
            与
            &nbsp;
            <a target="_blank" style={{ color: '#A0C3FF', textDecoration: 'none' }}
                href={chinese ? window.PROPS.privacyProtocolChineseUrl : window.PROPS.privacyProtocolEnglishUrl}>
                隐私协议</a>
            &nbsp; */}
        </div>
    )
}
