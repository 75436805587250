import React, { PureComponent } from 'react'
import { Tooltip } from 'antd';
export default class TeamCard extends PureComponent {

    render() {
        return (
            <div className='teamCard' style = {{position: 'relative'}} onClick={() => this.props.onClick(this.props.teamId, this.props.appId)}>
                <div style={{padding: 16, float: "left"}}>
                    <Avatar name={this.props.teamName} url = {this.props.avatarUrl}></Avatar>
                </div>
                <div className='teamName' style={{float: "left"}}>
                    <div
                        className="text-overflow"
                        style={{ float: 'left', maxWidth: 163 }}
                        onMouseEnter={(e) => this.props.showToolTip(e, this.props)}
                    >
                        {this.props.show ? (
                            <Tooltip title={this.props.teamName}>
                                <span>{this.props.teamName}</span>
                            </Tooltip>
                        ) : (
                            <span>{this.props.teamName}</span>
                        )}
                    </div>
                </div>
                <img style = {{position: 'absolute', right: 0}} src={`${window.PROPS.imagePath}/team-cycle.png`} alt='team-cycle'></img>
                {this.props.appName && this.props.appId ? <CategoryTag name = {this.props.appName}></CategoryTag>: <span></span>}
                <img className='selectedArrows' src={`${window.PROPS.imagePath}/selected.png`} alt='selected-arraws'></img>
            </div>
        )
    }
}

function Avatar({ url, name }) {
    let text = name ? name.substring(0,1) : 'M'
    return (
        <div className="avatar">
            {
                url ? <img className='img-avatar' src={url} alt='avatar'></img> 
                : <div className='text-avatar'>{text}</div>
            }
        </div>
    )
}

function CategoryTag({name}) {
    return (
        <div className='tag categoryTag text-overflow'>
            {name}
        </div> 
    )
}