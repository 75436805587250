import React, { PureComponent } from "react";
import MobileInputFrame from "./MobileInputFrame";
import VCodeInputFrame from "./VCodeInputFrame";
import SelectTeamFrame from "./SelectTeamFrame";
import ScanLoginFrame from "./ScanLoginFrame";
import cookie from "react-cookies";
import { FetchPost, request } from "../Common";
import { message } from "antd";
import "url-polyfill";

export default class LoginBox extends PureComponent {
  state = {
    clientId: "",
    returnUrl: "",
    chinese: true,
    onSelectTeamFunc: null,
    teamUserInfoList: [],
    currentFrame: () => <div></div>,
  };

  componentWillMount() {
    let searchParams = new URL(window.location.href).searchParams;
    let paramClientId = searchParams.get("clientId");
    let paramReturnUrl = searchParams.get("ReturnUrl");
    let lang = searchParams.get("lang");
    lang = lang ? lang : cookie.load("focus-lang");
    this.setState(
      {
        clientId: paramClientId ? paramClientId : window.PROPS.defaultClientId,
        returnUrl: paramReturnUrl
          ? paramReturnUrl
          : window.PROPS.defaultReturnUrl,
        chinese: lang ? lang !== "en_US" : true,
      },
      () => {
        lang = this.state.chinese ? "zh_CN" : "en_US";
        cookie.save("focus-lang", lang, {
          domain: window.PROPS.cookieDomain,
          path: "/",
        });
      },
    );
  }

  componentDidMount() {
    this.initMobileInputFrame();
    // this.initScanLoginFrame();
    // this.initVerCodeVerifyFrame('18515597305', '86')
    // let teamList = [
    //     {
    //         teamId: 'aaa',
    //         teamFullName: '智能管理与效率平台部dsf',
    //         teamAvatar: '',
    //         appId: 'me',
    //         appName: '智联空间园区智联空间园区',
    //     },
    //     {
    //         teamId: 'bbb',
    //         teamFullName: '测试团队',
    //         teamAvatar: '',
    //         appId: 'me',
    //         appName: 'ME 行业版',
    //     },
    //     {
    //         teamId: 'ccc',
    //         teamFullName: '御风',
    //         teamAvatar: '',
    //     },
    //     {
    //         teamId: 'ddd',
    //         teamFullName: '御风',
    //         teamAvatar: '',
    //     },
    //     {
    //         teamId: 'eee',
    //         teamFullName: '御风',
    //         teamAvatar: '',
    //     }
    // ]
    // this.initSelectTeamFrame(teamList)
  }

  // initScanLoginFrame = () => {
  //   this.setState({
  //     currentFrame: (props) => {
  //       return (
  //         <div
  //           style={{
  //             paddingTop: 0,
  //             paddingBottom: 0,
  //             paddingLeft: 0,
  //             paddingRight: 0,
  //           }}
  //         >
  //           <ScanLoginFrame
  //             {...props}
  //             verifySuccessCallback={this.handleSuccessLogin}
  //           />
  //         </div>
  //       );
  //     },
  //   });
  // };

  initMobileInputFrame = () => {
    this.setState({
      currentFrame: (props) => {
        return (
          <div
            style={{
              paddingTop: 121,
              paddingBottom: 121,
              paddingLeft: 75,
              paddingRight: 75,
            }}
          >
            <MobileInputFrame
              {...props}
              returnUrl={this.state.returnUrl}
              clientId={this.state.clientId}
              sendVcode={this.sendVercode}
              sendVcodeSuccess={this.initVerCodeVerifyFrame}
            ></MobileInputFrame>
          </div>
        );
      },
    });
  };

  initVerCodeVerifyFrame = (mobile, countryCode) => {
    this.setState({
      currentFrame: (props) => {
        return (
          <div
            style={{
              paddingTop: 121,
              paddingBottom: 121,
              paddingLeft: 75,
              paddingRight: 75,
            }}
          >
            <VCodeInputFrame
              {...props}
              mobile={mobile}
              countryCode={countryCode}
              returnUrl={this.state.returnUrl}
              clientId={this.state.clientId}
              verifySuccessCallback={this.handleSuccessLogin}
              clickRollback={this.initMobileInputFrame}
              resendVCode={this.sendVercode}
            ></VCodeInputFrame>
          </div>
        );
      },
    });
  };

  sendVercode = async (mobile, countryCode, successCallBack, failCallBack) => {
    let data = await request(
      `/passport/api/login/send/verCode?ReturnUrl=${encodeURIComponent(
        this.state.returnUrl,
      )}&clientId=${encodeURIComponent(this.state.clientId)}`,
      {
        accountType: "GENERAL",
        countryCode,
        keyword: mobile,
        receiveType: "MOBILE",
      },
    );
    if (data?.receive) {
      if (successCallBack) {
        successCallBack();
      }
    } else {
      data.errorMsg && message.error(data.errorMsg);
      if (failCallBack) {
        failCallBack();
      }
    }
    // FetchPost(
    //   this.jointAuthMsg("/janus/login/appSendVerCode"),
    //   {
    //     keyword: mobile,
    //     countryCode: countryCode,
    //     receiveType: "MOBILE",
    //     accountType: "GENERAL",
    //   },
    //   (data) => {
    //     if (data.errorCode === "0" || data.errorCode === "10160021") {
    //       if (successCallBack) {
    //         successCallBack();
    //       }
    //     } else {
    //       if (failCallBack) {
    //         failCallBack();
    //       }
    //     }
    //   },
    // );
  };

  initSelectTeamFrame = (teamList) => {
    this.setState({
      currentFrame: (props) => {
        return (
          <SelectTeamFrame
            {...props}
            teamList={teamList}
            clickRollback={this.initMobileInputFrame}
            onSelectTeam={this.state.onSelectTeamFunc}
          ></SelectTeamFrame>
        );
      },
    });
  };

  jointAuthMsg(basePath) {
    let escapeReturnUrl = escape(this.state.returnUrl);
    return (
      basePath +
      "?ReturnUrl=" +
      escapeReturnUrl +
      "&clientId=" +
      this.state.clientId
    );
  }

  handleSuccessLogin = (data) => {
    console.log("data", data);
    let accessToken = data.accessToken;
    let teamUserInfoList = data.teamUserInfoList;
    let returnUrl = data.returnUrl || this.state.returnUrl;
    let appType = data.appType;

    if (appType === "INTERNAL") {
      this.internalLoginSuccess(accessToken, teamUserInfoList, returnUrl);
    } else if (appType === "OPEN") {
      if (teamUserInfoList.length === 0) {
        message.error("外部应用暂不支持个人账号!");
        return;
      }
      let exchangeCode = data.exchangeCode
      this.openLoginSuccess(accessToken, teamUserInfoList, returnUrl, exchangeCode);
      // this.redirectToBussinessPage(
      //   accessToken,
      //   teamUserInfoList[0].teamId,
      //   teamUserInfoList[0].appId,
      //   returnUrl,
      //   data.openCode,
      //   false,
      // );
    } else {
      message.error("未知应用类型!");
    }
  };

  requestOpenCode(accessToken, teamId, appId, returnUrl, exchangeCode) {
    FetchPost(
      this.jointAuthMsg(window.PROPS.path.exchangeOpenCode),
      {
        accessToken: accessToken,
        teamId: teamId,
        exchangeCode: exchangeCode,
      },
      (data) => {
        if (data.errorCode === "0") {
          this.redirectToBussinessPage(
            accessToken,
            teamId,
            appId,
            returnUrl,
            data.content.openCode,
            false,
          );
        } else {
          this.setState({ buttonStatus: "nomal" });
        }
      },
    );
  }

  openLoginSuccess(accessToken, teamUserInfoList, returnUrl, exchangeCode) {
    if (teamUserInfoList.length === 1) {
      this.requestOpenCode(
        accessToken,
        teamUserInfoList[0].teamId,
        teamUserInfoList[0].appId,
        returnUrl,
        exchangeCode,
      );
    } else {
      this.showSelectTeamBox(teamUserInfoList, (teamId, appId) => {
        this.requestOpenCode(
          accessToken,
          teamId,
          appId,
          returnUrl,
          exchangeCode,
        );
      });
    }
  }

  internalLoginSuccess(accessToken, teamUserInfoList, returnUrl) {
    let url = new URL(unescape(returnUrl));
    if (url.hostname.endsWith(window.PROPS.cookieDomain)) {
      if (teamUserInfoList.length === 0) {
        this.redirectToBussinessPage(
          accessToken,
          null,
          null,
          returnUrl,
          null,
          true,
        );
      } else if (teamUserInfoList.length === 1) {
        this.redirectToBussinessPage(
          accessToken,
          teamUserInfoList[0].teamId,
          teamUserInfoList[0].appId,
          returnUrl,
          null,
          true,
        );
      } else {
        this.showSelectTeamBox(teamUserInfoList, (teamId, appId) => {
          this.redirectToBussinessPage(
            accessToken,
            teamId,
            appId,
            returnUrl,
            null,
            true,
          );
        });
      }
    } else {
      if (teamUserInfoList.length === 0) {
        this.redirectToBussinessPage(
          accessToken,
          null,
          null,
          returnUrl,
          null,
          false,
        );
      } else if (teamUserInfoList.length === 1) {
        this.redirectToBussinessPage(
          accessToken,
          teamUserInfoList[0].teamId,
          teamUserInfoList[0].appId,
          returnUrl,
          null,
          false,
        );
      } else {
        this.showSelectTeamBox(teamUserInfoList, (teamId, appId) => {
          this.redirectToBussinessPage(
            accessToken,
            teamId,
            appId,
            returnUrl,
            null,
            false,
          );
        });
      }
    }
  }

  redirectToBussinessPage(
    accessToken,
    teamId,
    appId,
    returnUrl,
    openCode,
    internal,
  ) {
    let url = unescape(returnUrl);
    let lang = this.state.chinese ? "zh_CN" : "en_US";
    let originDomain = window.PROPS.cookieDomain;
    if (teamId) {
      cookie.save("focus-team-id", teamId, { domain: originDomain, path: "/" });
    }
    if (appId) {
      cookie.save("focus-app-id", appId, { domain: originDomain, path: "/" });
    }
    cookie.save("focus-token", accessToken, {
      domain: originDomain,
      path: "/",
    });
    cookie.save("focus-lang", lang, { domain: originDomain, path: "/" });
    if (!internal) {
      if (openCode) {
        let urlObj = new URL(url);
        urlObj.searchParams.append("focus-open-code", openCode);
        url = urlObj.toString();
      } else {
        message.error("应用配置有误!");
        console.log(openCode, internal);
        return;
      }
    }
    window.parent.location.assign(url);
  }

  showSelectTeamBox(teamUserInfoList, onSelectTeamFunc) {
    this.setState({ onSelectTeamFunc: onSelectTeamFunc }, () => {
      this.initSelectTeamFrame(teamUserInfoList);
    });
  }

  render() {
    return <div>{this.state.currentFrame()}</div>;
  }
}
