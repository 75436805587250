import React, { PureComponent } from 'react'
import SingleNumInput from './SingleNumInput'

export default class VCodeInput extends PureComponent {

    state = {
        value : '',
        nowFocusCode : '1',
        value1 : '',
        value2 : '',
        value3 : '',
        value4 : '',
        value5 : '',
        value6 : '',

    }

    onNumberInput = (number, code) => {
        if(code !== this.state.nowFocusCode){
            return;
        }
        setTimeout(()=>{
             switch (code) {
                case '1':
                    this.setState({nowFocusCode: '2', value1 : number}, this.changeValue)
                    break
                case '2':
                    this.setState({nowFocusCode: '3', value2 : number}, this.changeValue)
                    break
                case '3':
                    this.setState({nowFocusCode: '4', value3 : number}, this.changeValue)
                    break
                case '4':
                    this.setState({nowFocusCode: '5', value4 : number}, this.changeValue)
                    break
                case '5':
                    this.setState({nowFocusCode: '6', value5 : number}, this.changeValue)
                    break
                case '6':
                    this.setState({value6 : number}, this.changeValue)
                    break
                default:
                    break
            }
        }, 1)
       
    }

    toPreInput = code => {
        switch (code) {
            case '2':
                this.setState({nowFocusCode: '1', value1: ''}, this.changeValue)
                break
            case '3':
                this.setState({nowFocusCode: '2', value2: ''}, this.changeValue)
                break
            case '4':
                this.setState({nowFocusCode: '3', value3: ''}, this.changeValue)
                break
            case '5':
                this.setState({nowFocusCode: '4', value4: ''}, this.changeValue)
                break
            case '6':
                if (this.state.value6) {
                    this.setState({nowFocusCode: '6', value6: ''}, this.changeValue)
                } else {
                    this.setState({nowFocusCode: '5', value5: ''}, this.changeValue)
                }
                break
            default:
                break
        }
    }

    changeValue = () => {
        let value = this.state.value1 + this.state.value2 + this.state.value3 + this.state.value4 + this.state.value5 + this.state.value6;
        this.setState({value: value}, () => {
            if (this.props.onVerCodeChange) {
                this.props.onVerCodeChange(value)
            }
        })
    }

    render() {
        let inputBoxStyle = {
            fontFamily: 'PingFangSC-Bold',
            textAlign: 'center',
            alignItems: 'center',
            width: 311,
            height: 50,
        }

        let dividerStyle = {
            height : 1,
            width : 6,
            margin: 5.5,
            marginTop: 24,
            backgroundColor : '#232930',
            float: 'left',
        }

        return (
            <div style = {inputBoxStyle}>
                <div style = {{marginRight : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '1'
                        value = {this.state.value1}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
                <div style = {{marginRight : 3.5, marginLeft : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '2'
                        value = {this.state.value2}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
                <div style = {{marginRight : 3.5, marginLeft : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '3'
                        value = {this.state.value3}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
                <div style = {dividerStyle}></div>
                <div style = {{marginRight : 3.5, marginLeft : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '4'
                        value = {this.state.value4}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
                <div style = {{marginRight : 3.5, marginLeft : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '5'
                        value = {this.state.value5}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
                <div style = {{marginLeft : 3.5, float: 'left'}}>
                    <SingleNumInput
                        code = '6'
                        value = {this.state.value6}
                        onNumberInput = {this.onNumberInput}
                        toPreInput = {this.toPreInput}
                        nowFocusCode = {this.state.nowFocusCode}
                    ></SingleNumInput>
                </div>
            </div>
        )
    }
}
