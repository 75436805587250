import React, { PureComponent } from 'react'
import TeamCard from '../components/TeamCard'

export default class SelectTeamFrame extends PureComponent {

    state = {
        overflowTeamSize: 0,
        teamList: [],
        dropTeamDisPlay: 'none'
    }

    componentWillMount() {
        if (this.props.teamList.length <= 3) {
            this.setState({teamList: this.props.teamList})
        } else {
            this.setState({
                teamList: this.props.teamList.slice(0,3),
                overflowTeamSize: this.props.teamList.length - 3,
                dropTeamDisPlay: 'block'
            })
        }
    }

    isWin = () => {
        return (navigator.platform === 'Win32') || (navigator.platform === 'Windows')
    }

    onTeamCardClick = (teamId, appId) => {
        if (teamId && this.props.onSelectTeam) {
            this.props.onSelectTeam(teamId, appId)
        }
    }

    onDropTeamClick = () => {
        this.setState({
            teamList: this.props.teamList,
            dropTeamDisPlay: 'none'
        })
    }

    onShowToolTip = (e, row) => {
        let offsetWidth = 0;
        if (e.target.offsetWidth) offsetWidth = e.target.offsetWidth;
        if (e.target.lastChild.offsetWidth) offsetWidth = e.target.lastChild.offsetWidth;
        const showToolTip = offsetWidth > 163;
        const _teamList = this.state.teamList.map((item) => {
            if (item.teamName === row.teamFullName && showToolTip) {
                item.show = true;
            } else {
                item.show = false;
            }
            return item;
        });
        this.setState({ teamList: _teamList });
    };

    render() {
        return (
            <div style={{ width: 460, height: 588, background: '#FFFFFF', border: '1px solid #F0F3F3', borderRadius: 6, userSelect: 'none'}}>
                <div style={{  margin: 32, marginBottom: 16, width: 396 }}>
                    <div className='rollbackTag' style={{ marginBottom: 24, width: 60 }} onClick={this.props.clickRollback}>
                        <img style={{ height: 15, width: 'auto' }} src={`${window.PROPS.imagePath}/icon-back.png`} alt='back'></img>
                        &nbsp;&nbsp;返回
                    </div>
                    <div className='majorTitle' style={{ marginBottom: 13 }}>选择团队</div>
                    <div className='subTitle'>以下为已加入的团队，可直接进入。</div>
                </div>
                <div className={`${this.isWin() ? 'window-scrollbar' : ''}`} 
                    style={{ width: 458, height: 437, overflow: 'auto', paddingBottom: 11, paddingTop: 7 }}>
                    {
                        this.state.teamList.map(team => {
                            return (
                                <div key={team.teamId} style={{padding: 9, paddingLeft: 30, paddingRight:30}}>
                                    <TeamCard
                                        teamId = {team.teamId}
                                        teamName = {team.teamFullName}
                                        avatarUrl = {team.teamAvatarUrl}
                                        onClick = {this.onTeamCardClick}
                                        appName = {team.appName}
                                        appId = {team.appId}
                                        showToolTip={this.onShowToolTip}
                                        show={team.show}
                                    ></TeamCard>
                                </div>
                            )
                        })
                    }
                    <div className='linkText' onClick={this.onDropTeamClick} 
                        style={{textAlign: 'center', marginTop: 23, display: this.state.dropTeamDisPlay}}>
                        展开剩余{this.state.overflowTeamSize}个团队&nbsp;
                        <img src={`${window.PROPS.imagePath}/open_team_list.png`} 
                            style={{width: 14, height: 'auto'}} alt='open_team_list'></img>
                    </div>
                </div>
            </div>
        )
    }
}
